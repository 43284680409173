import { createStore } from 'redux'

const initialState = {
  sidebarShow: 'responsive',
  notification: false,
  company: false,
  listNotifications: [],
  level: 0
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'not':
      return { ...state, ...rest }
    case 'company':
      return { ...state, ...rest }
    default:
      return state
  }
};

const store = createStore(changeState);
export default store;